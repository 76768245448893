import { FaCircleChevronRight } from "react-icons/fa6";
import React, { useState, useEffect } from "react";

export const WelcomePopup = (props: any) => {
    const [hidePopup, setHidePopup] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        // Sprawdzamy, czy użytkownik już wcześniej zdecydował się ukryć popup
        const isPopupHidden = localStorage.getItem('hideWelcomePopup');
        if (isPopupHidden === 'true') {
            setHidePopup(true);
        }
    }, []);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleClosePopup = () => {
        if (isChecked) {
            // Zapisujemy informację o ukryciu popupu w localStorage
            localStorage.setItem('hideWelcomePopup', 'true');
        }
        props.setShowWelcomePopup(false);
    };

    // Jeśli użytkownik zdecydował się ukryć popup, nie renderujemy go
    if (hidePopup) {
        return null;
    }

    const Item = ({ title, description, warning = false, link }: {
        title: string,
        description: string,
        warning?: boolean,
        link: string
    }) => {
        const [isExpanded, setIsExpanded] = useState<boolean>(false);

        return (
            <div className="Welcome-item-container" onClick={() => setIsExpanded(!isExpanded)}
                 style={{ borderLeftColor: warning ? "#a6163b" : "#4a6c4c" }}>
                <div className="Welcome-item-header">
                    <h2>{title}</h2>
                    <FaCircleChevronRight
                        size={18}
                        className="Welcome-item-chevron"
                        style={{ rotate: isExpanded ? "90deg" : "unset" }}
                    />
                </div>
                <div className="Welcome-item-description" style={{ maxHeight: isExpanded ? 300 : 0 }}>
                    <div className="List-item-spacer" style={{ marginTop: 10 }} />
                    <p className="Welcome-item-description-text">{description}</p>
                    <p className="Welcome-item-text-link"><a href={link} target="_blank" rel="noopener noreferrer">Czytaj więcej…</a></p>
                </div>
            </div>
        );
    };

    return (
        <div className="Welcome-wrapper">
            <div className="Welcome-content-background">
                <Item
                    title="Polityka plików cookies"
                    description="Strona używa plików cookies do celów reklamowych i analitycznych. Nie pobieramy, nie przetwarzamy i nie zapisujemy żadnych wrażliwych danych osobowych użytkowników."
                    link="/polityka-plikow-cookies.html"
                />
                <Item
                    title="Warunki użytkowania"
                    description="Strona służy celom informacyjnym. Przed podjęciem decyzji dotyczących roślin, skonsultuj się z weterynarzem lub specjalistą. Nadmierne spożycie nawet bezpiecznej rośliny może powodować zatrucie."
                    warning={true}
                    link="/warunki-uzytkowania.html"
                />
                <p>Korzystając z tej strony potwierdzasz, że zgadzasz się na politykę plików cookies i&nbsp;warunki użytkowania.</p>
                <div className="App-button" onClick={handleClosePopup}><h3>Rozumiem</h3></div>
                <div className="Welcome-checkbox">
                    <input
                        type="checkbox"
                        id="hidePopupCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="hidePopupCheckbox">Nie pokazuj więcej</label>
                </div>
            </div>
        </div>
    );
};
