import {Highlight} from "react-instantsearch";
import {useEffect, useState} from "react";
import imagePlaceholder from "../assets/images/flower-image-placeholder.jpg";
import {BsRadioactive} from "react-icons/bs";
import {LuCat, LuDog} from "react-icons/lu";

export const Hit = ({hit}: { hit: any }) => {
    const [isExpanded, setIsExpaded] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState(imagePlaceholder);

    useEffect(() => {
        const formattedLatinName = hit.latinName.toLowerCase().replace(/[\s.]/g, '_');
        const imagePath = `/flower_images/${formattedLatinName}.jpg`;

        const img = new Image();
        img.src = imagePath;

        img.onload = () => {
            setImageSrc(imagePath);
        };

        img.onerror = () => {
            setImageSrc(imagePlaceholder);
        };
    }, [hit.latinName]);

    return (
        <article className="List-item-container"
                 style={{borderLeftColor: hit.okForCats ? "#4a6c4c" : "#a6163b", borderLeftWidth: 3}}
                 onClick={() => setIsExpaded(!isExpanded)}
        >
            <div className="List-item-top" style={{flexDirection: isExpanded ? "column" : "row"}}>
                <img className="List-item-flower-preview"
                     style={{width: isExpanded ? "100%" : 80, borderRadius: isExpanded ? 5 : "50%"}} src={imageSrc}
                     alt=""/>
                <div className="List-item-details-container"
                     style={{width: isExpanded ? "100%" : "unset", alignItems: isExpanded ? "center" : "unset"}}>
                    <div
                        style={{display: "flex", flexDirection: "column", alignItems: isExpanded ? "center" : "unset"}}>
                        <p className="List-item-header">Polska nazwa rośliny:</p>
                        <h2 className="List-item-details"
                            style={{textAlign: isExpanded ? "center" : "left"}}
                        >
                            <Highlight attribute="polishName" highlightedTagName="em" hit={hit}/>
                        </h2>
                    </div>
                    <div
                        style={{display: "flex", flexDirection: "column", alignItems: isExpanded ? "center" : "unset"}}>
                        <p className="List-item-header">Nazwa systematyczna:</p>
                        <h2 className="List-item-details"
                            style={{textAlign: isExpanded ? "center" : "left"}}
                        >
                            <Highlight attribute="latinName" highlightedTagName="em" hit={hit}/>
                        </h2>
                    </div>
                </div>
            </div>
            <div className="List-item-spacer"/>
            {hit.okForCats === hit.okForDogs ? (
                <div className="List-item-bottom">
                    {!hit.okForCats ? (
                        <>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <BsRadioactive className="Bad" size={30}/>
                            </div>
                            <h2 className="Bad">NIEBEZPIECZNA<br/>DLA&nbsp;KOTÓW&nbsp;I&nbsp;PSÓW</h2>
                        </>
                    ) : (
                        <>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <LuCat className="Ok" size={30}/><LuDog className="Ok" size={30}/>
                            </div>
                            <h2 className="Ok">BEZPIECZNA<br/>DLA KOTÓW I&nbsp;PSÓW</h2>
                        </>
                    )}
                </div>
            ) : (
                <div className="List-item-icons-container">
                    <div className="List-item-row">
                        {!hit.okForCats ? <BsRadioactive className="Bad" size={30}/> :
                            <LuCat className="Ok" size={30}/>}
                        <h2 className={hit.okForCats ? "Ok" : "Bad"}>
                            {!hit.okForCats && "NIE"}BEZPIECZNA<br/>DLA KOTÓW
                        </h2>
                    </div>
                    <div className="List-item-row">
                        {!hit.okForDogs ? <BsRadioactive className="Bad" size={30}/> :
                            <LuDog className="Ok" size={30}/>}
                        <h2 className={hit.okForDogs ? "Ok" : "Bad"}>
                            {!hit.okForDogs && "NIE"}BEZPIECZNA<br/>DLA PSÓW
                        </h2>
                    </div>
                </div>
            )}
            {isExpanded && hit.photoCredits && (
                <>
                    <div className="List-item-spacer"/>
                    <p className="List-item-photoCredits">Źródło
                        zdjęcia:<br/>{hit.photoCredits}</p>
                </>
            )}
        </article>
    );
};