import React, {useState} from 'react';
import bazaRoslin from './assets/images/cat_and_dog.png';
import './App.css';
import {IoMdFlower} from "react-icons/io";
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import {Hits, InstantSearch, SearchBox, Stats, useInstantSearch} from "react-instantsearch";

import {Hit} from "./components/Hit";
import {WelcomePopup} from "./components/WelcomePopup";
import algoliaLogo from "./assets/images/algolia-logo-white.png"

const searchClient = algoliasearch("J0MDVL16E9", "72e2f2f01b983555e3676d426eb66ebf");

function App() {
    const [showWelcomePopup, setShowWelcomePopup] = useState<boolean>(true);


    // @ts-ignore
    function NoResultsBoundary({children, fallback}) {
        const {results} = useInstantSearch();

        // The `__isArtificial` flag makes sure not to display the No Results message
        // when no hits have been returned.
        if (!results.__isArtificial && results.nbHits === 0) {
            return (
                <>
                    {fallback}
                    <div hidden>{children}</div>
                </>
            );
        }

        return children;
    }

    function NoResults() {
        return (
            <>
                <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
                    <Stats translations={{
                        rootElementText({nbHits}) {
                            return `Znaleziono pasujących roślin: ${nbHits}`;
                        }
                    }}/>
                </div>
                <div style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                }}>
                    <img src={bazaRoslin} className="App-main-image" alt=""/>
                </div>
            </>
        );
    }

    // @ts-ignore
    function EmptyQueryBoundary({children, fallback}) {
        const {indexUiState} = useInstantSearch();

        if (!indexUiState.query) {
            return (
                <>
                    <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
                        <Stats translations={{
                            rootElementText({nbHits}) {
                                return `Roślin w bazie: ${nbHits}`;
                            }
                        }}/>
                    </div>
                    <main className="App-header">
                        <p className="App-description"><span>Wpisz nazwę rośliny</span>, której szukasz<br/>(po
                            polsku lub
                            łacińsku)<br/>i&nbsp;
                            <span>sprawdź</span>,
                            czy jest <span>bezpieczna</span><br/>dla
                            Twojego <span>kota</span> lub <span>psa</span>.
                        </p>
                    </main>
                    <img src={bazaRoslin} className="App-main-image" alt=""/>
                </>
            );
        }
        return children;
    }

    return (
        <>
            <InstantSearch
                searchClient={searchClient}
                indexName="ALGOLIA_BAZA_ROSLIN"
            >
                <div className="App-top-menu">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <h1 className="Ok" style={{letterSpacing: 6, margin: 0, padding: 0}}>BAZA R</h1>
                        <IoMdFlower size={28} color="#a6163b" style={{marginLeft: -4, marginRight: 2}}/>
                        <h1 className="Ok" style={{letterSpacing: 6}}>ŚLIN</h1>
                    </div>
                    <SearchBox classNames={{root: "App-search", form: "App-form", input: "App-input"}}
                               placeholder="tu wpisz nazwę szukanej rośliny"
                    />
                </div>
                <div className="App">
                    <EmptyQueryBoundary fallback={null}>
                        <NoResultsBoundary fallback={<NoResults/>}>
                            <div style={{display: "flex", gap: 5, alignItems: "center", justifyContent: "center"}}>
                                <Stats translations={{
                                    rootElementText({nbHits}) {
                                        return `Znaleziono pasujących roślin: ${nbHits}`;
                                    }
                                }}/>
                            </div>
                            <Hits
                                classNames={{
                                    root: 'List-root',
                                    list: 'List-list',
                                    item: "List-item"
                                }}
                                hitComponent={Hit}
                            />
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 5}}>
                                <p>Napędzane przez</p>
                                <div style={{backgroundColor: "#4a6c4c", padding: "8px 8px", borderRadius: 18,display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <img style={{height: 16}} src={algoliaLogo} alt="Algolia"/>
                                </div>
                            </div>
                        </NoResultsBoundary>
                    </EmptyQueryBoundary>
                    <div className="App-credits">
                        Baza Roślin © 2024 &#60;web:dawca/&#62;
                    </div>
                </div>

            </InstantSearch>

            {showWelcomePopup && <WelcomePopup setShowWelcomePopup={setShowWelcomePopup}/>}
        </>

    );
}

export default App;
